/* eslint-disable import/prefer-default-export */

import MapIcon from '../../assets/svg/map.svg'
import UnitIcon from '../../assets/svg/unit.svg'
import next from '../../assets/images/next.png'
import back from '../../assets/images/back.png'

export const Assets = {
  MapIcon,
  UnitIcon,
  next,
  back,
}
